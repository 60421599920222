import "./program-comparison-tray.scss";

import { useState } from "react";
import cn from "clsx";
import { useProgramComparison } from "design-system/hooks/use-program-comparison";
import { CtaLink } from "design-system/components/primitives/cta-link/cta-link";
import { Icon } from "design-system/components/icons/icon";
import { ExEdContentReferencesEntry } from "frontend/contentful/schema/universal";
import { DataLayerEvent, useDataLayer } from "frontend/hooks/use-data-layer";

export interface ProgramComparisonTrayProps {
  programFinderUrl?: string;
  isHBSOnline?: boolean;
  contentReferences?: ExEdContentReferencesEntry;
  dataLayer?: DataLayerEvent;
}

export function ProgramComparisonTray({
  programFinderUrl,
  isHBSOnline,
  /* eslint-disable-next-line camelcase */
  dataLayer = { event: "program_finder_result_click", event_data: {} },
}: ProgramComparisonTrayProps) {
  const [isTrayHidden, setIsTrayHidden] = useState(false);

  const { programsToCompare, removeProgramToCompare } = useProgramComparison();

  const { pushDataLayer } = useDataLayer();

  // Show the tray when adding new programs to compare
  const [prevProgramsLength, setPrevProgramsLength] = useState(
    programsToCompare.length,
  );
  if (prevProgramsLength !== programsToCompare.length) {
    setPrevProgramsLength(programsToCompare.length);

    if (prevProgramsLength < programsToCompare.length) {
      setIsTrayHidden(false);
    }
  }

  if (programsToCompare.length < 1) {
    return null;
  }

  const trayClass = cn(
    "hbs-program-comparison-tray__inner",
    isTrayHidden && "hbs-program-comparison-tray__inner--hidden",
    isHBSOnline && "hbs-program-comparison-tray__inner--online",
  );

  const programIds = programsToCompare.map((p) => p.id).join(",");

  const pageUrl = new URL(
    programFinderUrl || window.location.href,
    window.location.origin,
  );
  if (programIds) {
    pageUrl.searchParams.set("programs", programIds);
  }

  return (
    <div
      className={cn(
        "hbs-program-comparison-tray",
        isHBSOnline && "hbs-program-comparison-tray--online",
      )}
    >
      <h2 className="hbs-global-visually-hidden">Compare Programs</h2>
      <button
        onClick={() => setIsTrayHidden(false)}
        className={cn(
          "hbs-program-comparison-tray__fab",
          !isTrayHidden && "hbs-program-comparison-tray__fab--hidden",
        )}
      >
        <Icon
          name="compare"
          className="hbs-program-comparison-tray__fab-icon"
        />
        <span className="hbs-global-visually-hidden">Show comparison tray</span>
      </button>
      <div className={trayClass}>
        <button
          onClick={() => setIsTrayHidden(true)}
          className="hbs-program-comparison-tray__hide"
          aria-label="Hide comparison tray"
        >
          Hide
          <Icon name="close" />
        </button>

        <div className="hbs-program-comparison-tray__track">
          <ul className="hbs-program-comparison-tray__list">
            {programsToCompare.map((program) => (
              <li
                key={program.id}
                className="hbs-program-comparison-tray__item"
              >
                <p className="hbs-program-comparison-tray__item-title">
                  {program.title}
                </p>
                <button
                  onClick={() => {
                    removeProgramToCompare(program);
                    /* eslint-disable camelcase */
                    if (!dataLayer.event) {
                      dataLayer.event = "program_finder_result_click";
                    }
                    if (dataLayer.event_data) {
                      dataLayer.event_data.program_action =
                        "compare remove click";
                      dataLayer.event_data.program_name = program.title;
                      pushDataLayer(dataLayer);
                    } else {
                      dataLayer.event_data = {
                        program_action: "compare remove click",
                        program_name: program.title,
                      };
                    }
                    pushDataLayer(dataLayer);
                    /* eslint-enable camelcase */
                  }}
                  className="hbs-program-comparison-tray__remove"
                >
                  <span className="hbs-global-visually-hidden">
                    Remove {program.title}
                  </span>
                  <Icon name="close" />
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="hbs-program-comparison-tray__actions">
          <CtaLink
            className={cn(
              "hbs-program-comparison-tray__compare",
              programsToCompare.length < 2 &&
                "hbs-program-comparison-tray__compare--disabled",
            )}
            href={pageUrl.toString()}
            isSmall={true}
            type={"primary-button"}
          >
            Compare{!isHBSOnline && " Programs"}
          </CtaLink>
          {isHBSOnline && (
            <CtaLink
              className="hbs-program-comparison-tray__compare"
              href="#"
              isSmall={true}
              type={"secondary-button"}
              icon="arrow"
            >
              Request Info
            </CtaLink>
          )}
        </div>
      </div>
    </div>
  );
}
