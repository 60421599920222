import { createContentfulComponent } from "frontend/contentful/lib/create-contentful-component";
import dynamic from "next/dynamic";
import {
  LandingPage,
  LandingPageTopper,
} from "design-system/components/pages/landing-page/landing-page";
import { createSiteHeaderProps } from "../universal/site-header.props";
import { createSiteFooterProps } from "../universal/site-footer.props";
import { LandingPageEntry } from "frontend/contentful/schema/pages";
import { usePageProps } from "frontend/hooks/use-page-props";
import { ContentfulHead } from "../primitives/head.contentful";
import { ContentfulPageSections } from "./page-sections.contentful";
import { usePageNavigation } from "frontend/hooks/use-page-navigation";
import { createLazyTopperProps } from "../toppers/lazy-topper.props";
import { Footnotes } from "design-system/components/primitives/footnote/footnotes";
import { usePageFootnotes } from "frontend/hooks/use-page-footnotes";
import { useContentful } from "frontend/hooks/use-contentful";
import { ProgramComparisonProvider } from "design-system/hooks/use-program-comparison";
import { ProgramComparisonTray } from "design-system/sites/exed/program-comparison-tray/program-comparison-tray";

const ContentfulHomePage = dynamic(() =>
  import("./home-page.contentful").then((e) => ({
    default: e.ContentfulHomePage,
  })),
);

export const ContentfulLandingPage =
  createContentfulComponent<LandingPageEntry>(({ entry }) => {
    const { singletons, includes } = usePageProps();
    const { site, getEntry, getEntryUrl } = useContentful();
    const { breadcrumbs } = usePageNavigation();
    const footnotes = usePageFootnotes(entry.fields.blocks);

    if (singletons.isWWWHomePage) {
      return <ContentfulHomePage entry={entry} />;
    }

    // This is a temporary solution to determine if the page needs the program comparison context & tray.
    // It is currently needed for ExEd pages, and will be needed for HBS Online pages in the future.
    // Consider a refactor to expose global site-specific context/state in a more structured way.
    const needsProgramComparison = includes.Entry.some(
      (i) => i.sys.contentType.sys.id === "teaseRowProgramCards",
    );

    let programFinderUrl: string | undefined;
    if (site?.fields.contentReferences) {
      const contentReferences = getEntry(site.fields.contentReferences);
      const programFinderPageRef = contentReferences?.fields.programFinderPage;
      if (programFinderPageRef) {
        programFinderUrl = getEntryUrl(programFinderPageRef);
      }
    }

    return (
      <>
        <ContentfulHead entry={entry.fields.seo} />

        <LandingPage
          breadcrumbs={breadcrumbs}
          topper={
            createLazyTopperProps(entry.fields.topper) as LandingPageTopper
          }
          header={{ name: "SiteHeader", props: createSiteHeaderProps(site) }}
          footer={createSiteFooterProps(site?.fields.footer)}
        >
          {needsProgramComparison ? (
            <ProgramComparisonProvider programs={[]}>
              <ContentfulPageSections entry={entry} />
              <Footnotes footnotes={footnotes} />
              <ProgramComparisonTray
                isHBSOnline={site?.fields.domain === "online"}
                programFinderUrl={programFinderUrl}
              />
            </ProgramComparisonProvider>
          ) : (
            <>
              <ContentfulPageSections entry={entry} />
              <Footnotes footnotes={footnotes} />
            </>
          )}
        </LandingPage>
      </>
    );
  });
