import { ContentfulEntry, EntryRef } from "frontend/contentful/schema/sys";
import { useContentful } from "./use-contentful";
import { FootnoteProps } from "design-system/components/primitives/footnote/footnotes";
import { Block, INLINES, Inline, Text } from "@contentful/rich-text-types";
import { FootnoteEntry } from "frontend/contentful/schema/primitives";
import { isContentType } from "frontend/contentful/lib/is-content-type";
import { RenderContentfulRichText } from "frontend/contentful/components/utils/render-contentful-rich-text";
import { RichTextEntry } from "frontend/contentful/schema/blocks";

export function usePageFootnotes(blocks?: Array<EntryRef<ContentfulEntry>>) {
  const { getEntry, createEditAttributes } = useContentful();

  const footnotes: Array<FootnoteProps> = [];

  if (!Array.isArray(blocks)) return footnotes;

  const searchForFootnote = (node: Block | Inline | Text): void => {
    if (node.nodeType === INLINES.EMBEDDED_ENTRY) {
      const footnote = getEntry(node.data.target as ContentfulEntry);

      if (isContentType<FootnoteEntry>("footnote", footnote)) {
        footnotes.push({
          children: (
            <RenderContentfulRichText
              document={footnote.fields.text}
              disableParagraphs
            />
          ),
          editAttributes: createEditAttributes({
            entry: footnote,
            fieldId: "text",
          }),
        });
      }
    }

    if ("content" in node) {
      node.content.forEach(searchForFootnote);
    }
  };

  blocks.forEach((block) => {
    const entry = getEntry(block);

    if (isContentType<RichTextEntry>("richText", entry)) {
      entry.fields?.text?.content.forEach((content) => {
        content.content.forEach((content) => searchForFootnote(content));
      });
    }
  });

  return footnotes;
}
