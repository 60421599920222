import "./landing-page.scss";

import cn from "clsx";
import { ReactNode } from "react";
import {
  BaseLayout,
  SiteHeaderComponent,
} from "design-system/components/layouts/base";
import { BreadcrumbsProps } from "design-system/components/primitives/breadcrumbs/breadcrumbs";
import { LandingPageBody } from "design-system/components/pages/landing-page/landing-page-body/landing-page-body";
import { SiteFooterProps } from "design-system/components/universal/site-footer/site-footer";
import { ExtractLazyComponent } from "design-system/components/system/lazy-component/lazy-component";

export type LandingPageTopper = ExtractLazyComponent<
  | "CardTopper"
  | "MediaTopper"
  | "SplitTopper"
  | "StatisticsTopper"
  | "EditorialTopper"
  | "LargeAssetTopper"
  | "FeaturedTermTopper"
  | "SimplePageTopper"
>;

export interface LandingPageProps {
  header?: SiteHeaderComponent;
  footer?: SiteFooterProps;
  breadcrumbs?: BreadcrumbsProps;
  topper?: LandingPageTopper;
  children?: ReactNode;
}

export function LandingPage({
  children,
  breadcrumbs,
  footer,
  header,
  topper,
}: LandingPageProps) {
  return (
    <BaseLayout
      footer={footer}
      header={header}
      className={cn(
        "hbs-page",
        "hbs-landing-page",
        topper?.name && `hbs-page--${topper?.name}`,
      )}
    >
      <LandingPageBody breadcrumbs={breadcrumbs} topper={topper}>
        {children}
      </LandingPageBody>
    </BaseLayout>
  );
}
