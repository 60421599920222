import {
  Breadcrumbs,
  BreadcrumbsProps,
} from "design-system/components/primitives/breadcrumbs/breadcrumbs";
import "./landing-page-body.scss";
import { ReactNode } from "react";
import { LandingPageTopper } from "../landing-page";
import { LazyComponent } from "design-system/components/system/lazy-component/lazy-component";

export interface LandingPageBodyProps {
  breadcrumbs?: BreadcrumbsProps;
  topper?: LandingPageTopper;
  children?: ReactNode;
}

export function LandingPageBody({
  breadcrumbs,
  topper,
  children,
}: LandingPageBodyProps) {
  return (
    <main id="skipto-main" tabIndex={-1}>
      {topper && <LazyComponent {...topper} />}

      {breadcrumbs && <Breadcrumbs {...breadcrumbs} />}

      <div className="hbs-landing-page-body__wrapper">
        <div className="hbs-landing-page-body">
          <div className="hbs-landing-page-body__main">
            <section className="hbs-page-body hbs-landing-page-body__content">
              {children}
            </section>
          </div>
        </div>
      </div>
    </main>
  );
}
